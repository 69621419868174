import 'jspdf-autotable';

import * as jsPDF from 'jspdf/dist/jspdf.debug';

import environment from '../../../environment';
import {tsAndCs} from '../../../environment/constants';

window.html2canvas = require('html2canvas');

const {bookingUrl} = environment.settings;

MyBookingsModal.$inject = [
  '$timeout',
  'booking',
  'newBooking',
  'close',
  'BookingValueService',
  'DataLayerService',
];

export default function MyBookingsModal(
  $timeout,
  booking,
  newBooking,
  close,
  BookingValue,
  DataLayer
) {
  /** ============================================ *
   * View Model
   * ============================================= */

  const vm = this;

  /** ============================================ *
   * View Model : variables
   * ============================================= */

  BookingValue.set(booking);

  vm.Board = BookingValue.Board;
  vm.Booking = BookingValue.booking;
  vm.dates = BookingValue.dates;
  vm.price = BookingValue.price;
  vm.AccommodationType = BookingValue.AccommodationType;
  vm.Location = BookingValue.Location;
  vm.guests = BookingValue.guests;
  vm.newBooking = newBooking;

  vm.bookingsUrl = bookingUrl;
  vm.tsAndCsLink = tsAndCs.booking;

  /** ============================================ *
   * View Model : Functions
   * ============================================= */

  vm.hideModal = close;

  vm.print = print;

  newBooking && DataLayer.logHotelPurchase(BookingValue);

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */

  function print() {
    const booking = document.querySelector('.booking-pdf').innerHTML,
      pdf = new jsPDF('p', 'pt', 'a4'),
      margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522,
      },
      width = margins.width,
      elementHandlers = {
        '#bypassme': () => true,
      };

    $timeout(() => {
      pdf.fromHTML(
        booking, // HTML string or DOM elem ref.
        margins.left, // x coord
        margins.top, // y coord
        {
          width,
          elementHandlers,
        },
        () => {
          pdf.save(`${getFileName()}.pdf`);
          vm.printBookings = false;
        },
        margins
      );
    });
  }

  function getFileName() {
    const hotel = vm.Location.slug,
      id = vm.Booking.id,
      arrive = vm.Booking.arrive_date,
      depart = vm.Booking.depart_date;

    return `${hotel}-booking-${id}_${arrive}-${depart}`;
  }
}
