import environment from '../../../environment';
import {tsAndCs} from '../../../environment/constants';

const {bookingUrl} = environment.settings;

MyBookingsController.$inject = [
  '$stateParams',
  'ModalService',
  '$http',
  'BookingsJsonApi',
];

export default function MyBookingsController(
  $stateParams,
  ModalService,
  $http,
  JsonApi
) {
  /** ============================================ *
   * View Model
   * ============================================= */

  const startingPage = {
      page: 1,
    },
    pagination = {},
    vm = this;

  vm.booking = {};

  vm.noMoreResults = false;

  vm.showModal = showModal;
  vm.nextPage = nextPage;
  vm.bookingsUrl = bookingUrl;
  vm.tsAndCsLink = tsAndCs.booking;

  init();

  function showModal(booking, newBooking) {
    ModalService.showModal({
      templateUrl: 'app/view/bookings/elements/my-bookings-modal.html',
      controller: 'MyBookingsModalController',
      controllerAs: 'Booking',
      inputs: {
        booking: booking,
        newBooking: !!newBooking,
      },
      bodyClass: 'modal-open',
    });
  }

  /** ============================================ *
   * View Model : variables
   * ============================================= */

  function init() {
    angular.copy(startingPage, pagination);

    load();
  }

  function nextPage() {
    pagination.page++;

    load();
  }

  function load() {
    pagination.page === 1 && angular.copy({}, vm.booking);

    $http
      .get(JsonApi.buildUrl('Bookings', pagination))
      .then(response => JsonApi.handleResponse(response, handleResponse));
  }

  function handleResponse(data) {
    angular.forEach(data.Booking, (Booking, bookingId) => {
      Booking.status = getStatus(Booking);

      vm.booking[bookingId] = Booking;
    });

    vm.noMoreResults = angular.isArray(data.Booking);

    const newBookingId = $stateParams.bookingId;
    const hasNewBooking = newBookingId && vm.booking[newBookingId];
    const newBooking = vm.booking[newBookingId];

    hasNewBooking && showModal(newBooking, hasNewBooking);
  }

  function getStatus(booking) {
    return !booking.cancelled ? 'confirmed booking' : 'cancelled';
  }
}
