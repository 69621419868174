import angular from 'angular';

import FilterModule from '../common/filter/filter-module';
import {config, run} from './config';
import ControllerModule from './module/controller';
import DirectiveModule from './module/directive';
import ProviderModule from './module/provider';
import ServiceModule from './module/service';

export default angular
  .module('PartnerChoice.bookings', [
    'ngSanitize',
    'ui.router',
    'angularModalService',

    FilterModule.name,

    ControllerModule.name,
    DirectiveModule.name,
    ProviderModule.name,
    ServiceModule.name,
  ])
  .run(run)
  .config(config);
