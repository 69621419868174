import angular from 'angular';

import AuthInterceptor from './auth-interceptor';
import AuthToken from './auth-token-service';
import loginTokenService from './login-token-service';

export default angular
  .module('PartnerChoice.auth', [])
  .factory('AuthToken', AuthToken)
  .factory('AuthInterceptor', AuthInterceptor)
  .factory('LoginTokenService', loginTokenService);
