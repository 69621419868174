import {welcomePageUrl} from '../../../environment/constants';

const ProfileComponent = {
  templateUrl: '/app/assets/component/profile/component.html',
  controller: ProfileController,
};

export default ProfileComponent;

ProfileController.$inject = ['UserService', 'ModalService'];
export function ProfileController(user, ModalService) {
  const vm = this;

  vm.user = user.user;
  vm.welcomeUrl = welcomePageUrl;
  vm.editDetails = editDetails;
  vm.listAddresses = listAddresses;
  vm.editPassword = editPassword;
  vm.editNotifications = editNotifications;

  function listAddresses() {
    const modalConfig = {
      templateUrl: `app/view/modals/address-modal.html`,
      controller: 'listAddresses',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
    };
    ModalService.showModal(modalConfig);
  }

  function editDetails() {
    const data = {
      fields: [
        'first_name',
        'last_name',
        'email',
        'postcode',
        'branch_number',
        'retired',
      ],
    };

    editUserPart(data);
  }

  function editNotifications() {
    const fields = ['preferences'];
    const template = 'notifications';
    editUserPart({template, fields});
  }

  function editPassword() {
    const fields = ['email', 'password', 'passwordCurrent', 'passwordConfirm'];
    const template = 'password';

    editUserPart({fields, template});
  }

  function editUserPart({fields, template = 'personal-details'}) {
    const templateUrl = `app/view/modals/edit-${template}.html`;

    ModalService.showModal({
      templateUrl,
      controller: 'EditUserController',
      controllerAs: 'modal',
      bodyClass: 'modal-open',
      inputs: {
        fields,
      },
    });
  }
}
