import moment from 'moment';

import environment from '../../../environment';
import {tsAndCs} from '../../../environment/constants';

const {bookingUrl} = environment.settings;

BookingExtraInfoModal.$inject = [
  '$http',
  'BookingsJsonApi',
  '$state',
  'Option',
  'PackageSearchService',
  'LocationService',
  'BookingValueService',
  'PriceService',
  '$window',
  'close',
];

export default function BookingExtraInfoModal(
  $http,
  JsonApi,
  $state,
  Option,
  Search,
  Location,
  BookingValue,
  Price,
  $window,
  close
) {
  /** ============================================
   * View Model
   * ============================================= */

  const vm = this;

  /** ============================================
   * View Model : variables
   * ============================================= */

  vm.showErrors = false;
  vm.ExtraInfoForm = {};

  vm.bookingsUrl = bookingUrl;
  vm.tsAndCsLink = tsAndCs.booking;

  const checkin = moment(Search.availabilitySearch.checkin).startOf('day');
  const checkout = moment(Search.availabilitySearch.checkout).startOf('day');

  const nights = checkout.diff(checkin, 'days');

  BookingValue.set({
    AccommodationType: Option.AccommodationType,
    Location: Location.locations[Search.availabilitySearch.location],
    Board: Search.Board[Search.options.board],
    arrive_date: Search.availabilitySearch.checkin,
    depart_date: Search.availabilitySearch.checkout,
    nights,
    partner_adult_qty: Search.availabilitySearch.partnerAdult,
    partner_child_qty: Search.availabilitySearch.partnerChild,
    guest_adult_qty: Search.availabilitySearch.guestAdult,
    guest_child_qty: Search.availabilitySearch.guestChild,
    infant_qty: Search.availabilitySearch.infant,
    booking_options: {
      specialRequests: '',
      additionalGuests: [],
      contactPhoneNumber: '',
    },
  });

  vm.Board = BookingValue.Board;
  vm.Booking = BookingValue.booking;
  vm.dates = BookingValue.dates;
  vm.AccommodationType = BookingValue.AccommodationType;
  vm.Location = BookingValue.Location;
  vm.guests = BookingValue.guests;
  vm.optionPrice = Price.get({
    search: Search.availabilitySearch,
    AccommodationType: Option.AccommodationType,
    boardSlug: Search.options.board,
  });

  /** ============================================
   * View Model : Functions
   * ============================================= */

  vm.hideModal = close;

  vm.additonalGuestsNameValid = additonalGuestsNameValid;
  vm.additionalGuestBritishCitizenValid = additionalGuestBritishCitizenValid;
  vm.additionalGuestBritishCitizenNo = additionalGuestBritishCitizenNo;
  vm.openTermsAndConditions = openTermsAndConditions;

  vm.confirm = confirm;

  /** ============================================
   * Private/Protected functions
   * ============================================= */

  function openTermsAndConditions() {
    vm.ExtraInfoForm.agreeToTerms.$setValidity('linkOpened', true);

    $window.open(tsAndCs.booking);
  }

  function confirm() {
    if (!vm.ExtraInfoForm.$valid) {
      vm.showErrors = true;
    } else {
      doTheBooking();
    }
  }

  function doTheBooking() {
    const Booking = BookingValue.getSaveableBooking(),
      PriceValue = Price.saveablePrices({
        search: BookingValue.getSearch(),
        boardSlug: BookingValue.booking.Board.slug,
      });

    Object.assign(Booking, {
      peoplePrices: PriceValue.peoplePrices,
      boardPrices: PriceValue.boardPrices,
      supplements: PriceValue.supplements,
    });

    $http
      .post(JsonApi.buildUrl('Bookings'), Booking)
      .then(response => JsonApi.handleResponse(response, close));
  }

  function additonalGuestsNameValid() {
    for (
      let i = 0;
      i < vm.Booking.booking_options.additionalGuests.length;
      i++
    ) {
      if (vm.ExtraInfoForm['additionalGuests' + i].$invalid) {
        return false;
      }
    }

    return true;
  }

  function additionalGuestBritishCitizenValid() {
    let valid = true;

    for (
      let i = 0;
      i < vm.Booking.booking_options.additionalGuests.length;
      i++
    ) {
      if (
        vm.Booking.booking_options.additionalGuests[i].britishCitizen === null
      ) {
        valid = false;
      }
    }

    vm.ExtraInfoForm.$setValidity('britishCitizen', valid);

    return valid;
  }

  function additionalGuestBritishCitizenNo() {
    let citizen = true;

    for (
      let i = 0;
      i < vm.Booking.booking_options.additionalGuests.length;
      i++
    ) {
      if (
        vm.Booking.booking_options.additionalGuests[i].britishCitizen === 'no'
      ) {
        citizen = false;
      }
    }

    return citizen;
  }
}
