ConfigurationModal.$inject = ['Configuration', 'description', 'close'];

export default function ConfigurationModal(Configuration, description, close) {
  /** ============================================ *
   * View Model
   * ============================================= */

  const vm = this;

  /** ============================================ *
   * View Model : variables
   * ============================================= */

  vm.Configuration = Configuration;
  vm.description = description;

  /** ============================================ *
   * View Model : Functions
   * ============================================= */

  vm.hideModal = close;
  vm.getMinChild = getMinChild;

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */

  function getMinChild() {
    return Configuration.occupancy - Configuration.max_adult;
  }
}
