const CompanyComponent = {
  templateUrl: '/app/assets/component/company/component.html',
  controller: CompanyController,
};

export default CompanyComponent;

CompanyController.$inject = [
  'HeadService',
  'AuthToken',
  '$stateParams',
  '$http',
  'LeisureJsonApi',
  'ProductService',
  'GlobalStyle',
  'ProductRatingService',
];

export function CompanyController(
  Head,
  AuthToken,
  $stateParams,
  $http,
  JsonApi,
  ProductService,
  GlobalStyle,
  ProductRatingService
) {
  let productsOnPage = () => {};
  const vm = this;

  vm.showProducts = showProducts;
  vm.isPartOfTheGroup = isPartOfTheGroup;
  vm.leaveClub = leaveClub;
  vm.joinClub = joinClub;
  vm.registerProductsOnPage = register;
  vm.$onInit = init;
  vm.$onDestroy = destroy;

  vm.interact = () => {
    ProductRatingService.redeem(vm.company.Product.id);
  };
  function init() {
    vm.company = {};
    vm.items = ProductService.items;
    vm.limit = ProductService.limit;
    vm.nextPage = ProductService.nextPage;

    vm.nextCleanseDate = null;

    load();
    setDateString();

    GlobalStyle.setStyle('product');
  }

  function register(handler) {
    productsOnPage = handler;
  }

  function destroy() {
    GlobalStyle.setStyle();
  }

  function leaveClub() {
    club({leave: true});
  }

  function joinClub() {
    vm.interact();
    club({join: true});
  }

  function club(params) {
    $http.put(JsonApi.buildUrl('Clubs/' + vm.company.id, params));
  }

  function showProducts() {
    return (
      vm.items.length && (vm.company.filter !== 'club' || isPartOfTheGroup())
    );
  }

  function isPartOfTheGroup() {
    return AuthToken.hasCapability('groups', vm.company.Group.slug);
  }

  function load() {
    $http
      .get(JsonApi.buildUrl('Companies/' + $stateParams.slug))
      .then(response =>
        JsonApi.handleResponse(response, data => {
          const {Product, id: companyId} = data;
          const {ProductExtraInfo} = Product;

          const product_id = `${companyId}-${Product.id}`;

          const company = {
            ...data,
            product_id,
            Product: {
              ...Product,
              id: product_id,
              ProductExtraInfo: ProductExtraInfo.map(infoItem => ({
                ...infoItem,
                product_id,
              })),
            },
            hotelDisabled: true,
          };
          angular.copy(company, vm.company);

          Head.setTitle(data.title);

          ProductService.filter(
            item => data.itemIds.indexOf(item.id) > -1
          ).then(() => productsOnPage());

          ProductRatingService.view(product_id);
        })
      );
  }

  function setDateString() {
    let year;
    const string = '31/01/';
    const today = new Date();

    year = today.getFullYear();

    if (today.getMonth() > 0) {
      year++;
    }

    vm.nextCleanseDate = string + year;
  }
}
