import angular from 'angular';

import MyBookingsController from '../controller/my-bookings-controller';
import SearchController from '../controller/search-controller';
import SearchResultsController from '../controller/search-results-controller';
import BookingExtraInfoModal from '../modal/booking-extra-info-modal';
import ConfigurationModal from '../modal/configuration-modal';
import MyBookingsModal from '../modal/my-bookings-modal';
import SearchArrivalModal from '../modal/search-arrival-modal';

export default angular
  .module('PartnerChoice.booking.controller', [])
  .controller('MyBookingsController', MyBookingsController)
  .controller('SearchController', SearchController)
  .controller('SearchResultsController', SearchResultsController)

  .controller('MyBookingsModalController', MyBookingsModal)
  .controller('BookingExtraInfoModalController', BookingExtraInfoModal)
  .controller('ConfigurationModalController', ConfigurationModal)
  .controller('SearchArrivalController', SearchArrivalModal);
