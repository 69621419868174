forgottenController.$inject = ['$http', 'LeisureJsonApi', 'MessageService'];

export default function forgottenController($http, JsonApi, MessageService) {
  const vm = this;

  /** ============================================ *
   * View Variables
   * ============================================= */
  vm.user = {};
  vm.forms = {};

  vm.panel = {
    active: 'forgot',
  };

  /** ============================================ *
   * View Functions
   * ============================================= */
  vm.disabled = disabled;
  vm.submit = submit;

  /**
   * Should the submit button be disabled ?
   */
  function disabled() {
    return vm.forms.forgot.$invalid;
  }

  /**
   * Submit the forgotten password info
   */
  function submit() {
    !!doChecks() && pass();
  }

  function pass() {
    $http.post(JsonApi.buildUrl('/Forgotten'), vm.user).then(response => {
      JsonApi.handleResponse(response, () => {
        vm.panel.active = 'complete';
      });
    });
  }

  /**
   * Do some cheaks on passsword and employee umber,
   * not in browser s a user should not know what makes a valid employee number
   *
   * @returns {boolean}
   */
  function doChecks() {
    const valid = checkEmployeeNumber(vm.user.employee_number);

    !valid &&
      MessageService.error(
        'Your employee number is not valid, please check and try again.'
      );

    return valid;
  }

  function checkEmployeeNumber(employeeNumber) {
    return employeeNumber.toString().length == 8 && !isNaN(employeeNumber);
  }
}
