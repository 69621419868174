config.$inject = ['$stateProvider', 'BookingRoutesProvider'];

export default function config($stateProvider, RoutesProvider) {
  const routes = RoutesProvider.$get();

  $stateProvider
    .state('Hotel', routes['Hotel'])
    .state('Hotel.search', routes['Hotel.search'])
    .state('Hotel.search.result', routes['Hotel.search.result'])
    .state('Hotel.my-bookings', routes['Hotel.my-bookings']);
}
