import environment from '../../../environment';
import {tsAndCs} from '../../../environment/constants';
import dateFormat from '../config/date-format';

const {bookingUrl} = environment.settings;

const dayInMiliseconds = 24 * 60 * 60 * 1000;

SearchController.$inject = [
  '$filter',
  '$state',
  '$stateParams',
  'PackageSearchService',
  'LocationService',
  'ModalService',
  'AuthToken',
];

export default function SearchController(
  $filter,
  $state,
  $stateParams,
  Search,
  Location,
  ModalService,
  AuthToken
) {
  Search.clearErrors();

  /** ============================================ *
   * View Model
   * ============================================= */
  const vm = this;

  /** ============================================ *
   * View Model : variables
   * ============================================= */

  vm.restrictions = Search.restrictions;
  vm.locations = Location.locations;
  vm.errors = Search.errors;

  vm.search = Search.availabilitySearch;

  vm.checkin = null;
  vm.checkout = null;

  vm.tsAndCsLink = tsAndCs.booking;
  vm.bookingsUrl = bookingUrl;
  vm.DateFormat = dateFormat;

  vm.userIsRetired = AuthToken.hasCapability('groups', 'retired-partners');

  /** ============================================ *
   * View Model : Functions
   * ============================================= */
  vm.minCheckin = minCheckin;
  vm.maxCheckin = maxCheckin;

  vm.setCheckin = setCheckout;
  vm.setCheckout = setCheckout;

  vm.setOccupants = setOccupants;

  vm.searchAction = search;

  vm.dropdown = null;
  vm.setDropdown = setDropdown;

  vm.showModal = showModal;
  vm.getDate = getDate;

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */

  init();

  function getDate(phpDate) {
    // consistency is key
    return getDateFromString(phpDate.date);
  }

  function init() {
    const params = extractParams();

    Location.load(params.location);

    Search.setAvailabilitySearch(params);

    vm.search.checkin && angular.copy(new Date(vm.search.checkin), vm.checkin);
    vm.search.checkout &&
      angular.copy(new Date(vm.search.checkout), vm.checkout);

    if (vm.search.checkin && vm.search.checkout) {
      setNights();
    }
  }

  function extractParams() {
    const params = angular.copy($stateParams);

    !!params.checkin && (params.checkin = getCheckin(params));
    !!params.checkout && (params.checkout = getDateFromString(params.checkout));
    params.location = 'null';

    return params;
  }

  function getCheckin(params) {
    let checkin = getDateFromString(params.checkin),
      minCheckin = $filter('date')(Search.restrictions.checkin.min, 'yyyyMMdd'),
      maxCheckin = $filter('date')(Search.restrictions.checkin.max, 'yyyyMMdd'),
      check = $filter('date')(checkin, 'yyyyMMdd');

    check < minCheckin &&
      (checkin = angular.copy(Search.restrictions.checkin.min));
    check > maxCheckin &&
      (checkin = angular.copy(Search.restrictions.checkin.max));

    return angular.copy(checkin);
  }

  function getDateFromString(string) {
    let arr = string.split(/[- :]/),
      date;

    if (arr.length == 6) {
      date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    } else {
      date = new Date(arr[0], arr[1] - 1, arr[2]);
    }

    return date;
  }

  function setDropdown(dropdown) {
    dropdown && (dropdown = vm.dropdown == dropdown ? null : dropdown);

    vm.dropdown = dropdown;
  }

  function minCheckin() {
    return $filter('date')(vm.restrictions.checkin.min, dateFormat.filter.sql);
  }

  function maxCheckin() {
    return $filter('date')(vm.restrictions.checkin.max, dateFormat.filter.sql);
  }

  // function setCheckin() {
  //   setCheckout();

  //   updateUrl();
  // }

  /**
   * can be run at each change of nights or checkin
   */
  function setCheckout() {
    // reset
    vm.search.checkout = null;
    vm.search.checkin && vm.search.nights && updateCheckout();

    updateUrl();
  }

  /**
   * This should only be run if we have nights set
   * this should be checked before running this function
   * add the amount of days plus 12 hours then reset hours to 0 to allow for bst switching
   */
  function updateCheckout() {
    const checkout = new Date();

    checkout.setTime(
      vm.search.checkin.getTime() +
        vm.search.nights * dayInMiliseconds +
        dayInMiliseconds / 2
    );

    checkout.setHours(0);

    vm.search.checkout = checkout;
    setNights();
  }

  function setNights() {
    vm.search.nights = dateDiff(vm.search.checkin, vm.search.checkout);
  }

  function setOccupants() {
    updateUrl();
  }

  function updateUrl() {
    const search = angular.copy(vm.search);

    search.checkin &&
      (search.checkin = $filter('date')(search.checkin, dateFormat.filter.sql));
    search.checkout &&
      (search.checkout = $filter('date')(
        search.checkout,
        dateFormat.filter.sql
      ));

    $state.go('Hotel.search', search, {
      location: true,
      notify: false,
    });
  }

  function search() {
    vm.dropdown = null;
    vm.HotelSearchForm.$valid && Search.search();
  }

  function showModal() {
    ModalService.showModal({
      templateUrl: 'app/view/modals/search-arrival-modal.html',
      controller: 'SearchArrivalController',
      controllerAs: 'Search',
      inputs: {
        hotelSlug: Search.availabilitySearch.location,
      },
      bodyClass: 'modal-open',
    });
  }
}

/**
 *
 * Use Math.round to allow for possible discrepancies of daylight savings
 *
 * @param first
 * @param second
 * @returns {*}
 */
function dateDiff(first, second) {
  try {
    const timeDiff = Math.abs(second.getTime() - first.getTime());

    return Math.round(timeDiff / dayInMiliseconds);
  } catch (e) {
    return null;
  }
}
