CalendarService.$inject = [];

export default function CalendarService() {
  let startOfCalendar = null;

  return {
    buildMonth: buildMonth,
    buildWeek: buildWeek,
  };

  function buildMonth(firstOfCurrentMonth) {
    const weeks = [];

    startOfCalendar = firstOfCurrentMonth.clone();

    startOfCalendar.day(0);

    const firstOfWeek = startOfCalendar.clone();

    for (let i = 0; i < 6; i++) {
      weeks.push({days: buildWeek(firstOfWeek, firstOfCurrentMonth)});
      firstOfWeek.add(1, 'w');
    }

    return weeks;
  }

  function buildWeek(startOfWeek, firstOfCurrentMonth) {
    const days = [],
      currentDate = startOfWeek.clone();

    for (let i = 0; i < 7; i++) {
      const activeDay = currentDate.clone();

      const day = {
        number: activeDay.date(),
        isCurrentMonth: activeDay.month() === firstOfCurrentMonth.month(),
        date: activeDay,
        monthCode: activeDay.format('YYYYMM'),
        dayCode: activeDay.format('DD'),
      };

      days.push(day);
      currentDate.add(1, 'd');
    }
    return days;
  }
}
