import angular from 'angular';

import BoardService from '../service/board-service';
import BookingValueService from '../service/booking-value-service';
import BookingsJsonApiService from '../service/bookings-json-api-service';
import CalendarService from '../service/calendar-service';
import LocationService from '../service/location-service';
import PackageSearchService from '../service/package-search-service';
import PriceService from '../service/price-service';

export default angular
  .module('PartnerChoice.booking.service', [])
  .factory('BoardService', BoardService)
  .service('BookingsJsonApi', BookingsJsonApiService)
  .factory('BookingValueService', BookingValueService)
  .factory('CalendarService', CalendarService)
  .factory('LocationService', LocationService)
  .factory('PackageSearchService', PackageSearchService)
  .factory('PriceService', PriceService);
