import environment from '../../../../environment';

userClubsController.$inject = [
  '$http',
  'LeisureJsonApi',
  'ProductService',
  'AuthToken',
  'cfpLoadingBar',
];

export default function userClubsController(
  $http,
  JsonApi,
  ProductService,
  AuthToken,
  cfpLoadingBar
) {
  const vm = this;

  vm.items = ProductService.items;
  vm.limit = ProductService.limit;
  vm.nextPage = ProductService.nextPage;

  init();

  vm.loaded = function () {
    const progress = cfpLoadingBar.status();

    return progress === 0 || progress === 1;
  };

  function init() {
    $http
      .get(`${environment.settings.registeredAwsUrl}/clubs`)
      .then(response => JsonApi.handleResponse(response, loadItems));
  }

  function loadItems(data) {
    ProductService.filter(item => {
      const isClub = data.Company.indexOf(item.id) > -1;

      isClub && (item.member = AuthToken.hasCapability('groups', item.group));

      return isClub;
    });
  }
}
