import environment from '../../../environment';

const {bookingUrl} = environment.settings;

BookingsJsonApiService.$inject = ['JsonApi'];

export default function BookingsJsonApiService(JsonApi) {
  const service = this;

  service.buildUrl = buildUrl;
  service.handleResponse = JsonApi.handleResponse;

  function buildUrl(url, params) {
    return JsonApi.buildUrl(bookingUrl + '/json/' + url, params);
  }
}
