BoardService.$inject = ['$http', 'BookingsJsonApi'];

export default function BoardService($http, JsonApi) {
  const service = {
    board: {},
    load,
  };

  return service;

  function load() {
    $http.get(JsonApi.buildUrl('Boards')).then(
      response => {
        JsonApi.handleResponse(response, handleResponse);
      },
      () => {
        angular.noop();
      }
    );
  }

  function handleResponse(data) {
    angular.copy(data.Board, service.board);
  }
}
