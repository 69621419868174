LocationService.$inject = ['$http', 'BookingsJsonApi'];

export default function LocationService($http, JsonApi) {
  const service = {
    locations: {},
    load,
  };

  return service;

  function load(slug) {
    // angular.copy({}, service.locations);

    return $http
      .get(JsonApi.buildUrl('Locations', {slug: slug}))
      .then(response => {
        JsonApi.handleResponse(response, handleResponse);
      });
  }

  function handleResponse(data) {
    angular.forEach(data.Location, (location, slug) => {
      service.locations[slug] = location;
    });
  }
}
