PackageSearchService.$inject = [
  '$rootScope',
  '$state',
  '$http',
  '$filter',
  'BookingsJsonApi',
  'LocationService',
];

export default function PackageSearchService(
  $rootScope,
  $state,
  $http,
  $filter,
  JsonApi,
  Location
) {
  const dateFormat = 'yyyy-MM-dd';
  const today = new Date(),
    oneDayInMiliseconds = 24 * 60 * 60 * 1000,
    oneYearInMiliseconds = oneDayInMiliseconds * 365,
    service = {
      restrictions: {
        checkin: {
          min: new Date(today.getTime()),
          max: new Date(today.getTime() + oneYearInMiliseconds),
        },
        nights: {
          min: 2,
          max: 14,
        },
      },
      errors: {},
      packages: [],
      LocationPrices: {},
      Board: {},
      AccommodationTypePrices: {},
      roomOptions: [],
      options: {
        board: null,
      },
      availabilitySearch: {
        location: null,
        checkin: null,
        checkout: null,
        partnerAdult: 1,
        guestAdult: 0,
        partnerChild: 0,
        guestChild: 0,
        infant: 0,
      },
      setAvailabilitySearch: setAvailabilitySearch,
      search: search,
      clearErrors: resetErrors,
      clearPackages: clearPackages,
      fullOccupancy: fullOccupancy,
    };

  $rootScope.$watch(
    () => {
      const search = angular.copy(service.availabilitySearch);

      search.checkin = $filter('date')(search.checkin, dateFormat);
      search.checkout = $filter('date')(search.checkout, dateFormat);

      return search;
    },
    () => {
      clearPackages();
    },
    true
  );

  return service;

  function clearPackages() {
    angular.copy([], service.packages);
    angular.copy({}, service.prices);
    angular.copy({}, service.supplements);
  }

  function setAvailabilitySearch(field, value) {
    typeof field !== 'object' && setAvailabilitySearchValue(field, value);

    typeof field === 'object' &&
      angular.forEach(field, (value, key) => {
        setAvailabilitySearchValue(key, value);
      });
  }

  function setAvailabilitySearchValue(field, value) {
    service.availabilitySearch.hasOwnProperty(field) &&
      (service.availabilitySearch[field] = value);
  }

  /**
   * Do the search to php
   */
  function search() {
    const filters = angular.copy(service.availabilitySearch),
      location = filters.location;

    filters.locationId = Location.locations[filters.location].id;
    delete filters.location;

    filters.checkin &&
      (filters.checkin = $filter('date')(filters.checkin, dateFormat));
    filters.checkout &&
      (filters.checkout = $filter('date')(filters.checkout, dateFormat));

    resetErrors();

    $http
      .get(JsonApi.buildUrl('Packages', filters))
      .then(response => {
        JsonApi.handleResponse(response, packages => {
          const roomOptions = [],
            hasPackages = Object.keys(packages.Packages).length;

          angular.copy(packages.Packages, service.packages);
          angular.copy(packages.LocationPrices, service.LocationPrices);
          angular.copy(
            packages.AccommodationTypePrices,
            service.AccommodationTypePrices
          );
          angular.copy(packages.Board, service.Board);

          // Set Board selected
          service.options.board = Object.keys(
            service.LocationPrices.boardPrice
          )[0];

          for (let i = packages.minRooms; i <= packages.maxRooms; i++) {
            roomOptions.push(i);
          }
          angular.copy(roomOptions, service.roomOptions);

          filters.location = location;

          hasPackages && $state.transitionTo('Hotel.search.result', filters);
          !hasPackages && (service.errors['available'] = true);
        });
      })
      .finally(() => {
        $rootScope.$broadcast('partnerchoice:package-search-complete');
      });
  }

  function resetErrors() {
    angular.copy({}, service.errors);
  }

  function fullOccupancy() {
    return (
      service.availabilitySearch.partnerAdult +
      service.availabilitySearch.guestAdult +
      service.availabilitySearch.guestChild +
      service.availabilitySearch.partnerChild
    );
  }
}
