resetController.$inject = ['$stateParams', '$http', 'LeisureJsonApi', '$state'];

export default function resetController($stateParams, $http, JsonApi, $state) {
  const vm = this;

  vm.user = {};
  vm.errors = {};

  vm.reset = reset;
  vm.setUId = setUserId;
  vm.errorFields = errorFields;
  vm.display = {errors: false};

  init();

  function setUserId(userId) {
    vm.user.id = userId;
  }

  function reset() {
    $http
      .put(
        JsonApi.buildUrl(
          '/Forgotten/' + vm.user.id + '/' + $stateParams.userHash
        ),
        vm.user
      )
      .then(response => {
        JsonApi.handleResponse(response, respond, fails);
      });
  }

  function respond() {
    // success
    $state.go('Guest.Login', {
      reset: true,
    });
  }

  /**
   * Deal with an error
   * put error messages where they need to go
   *
   * @param response
   */
  function fails(response) {
    vm.display.errors = true;
    vm.errors = response;
  }

  /**
   * Return the error fields as an array
   *
   * @returns {Array}
   */
  function errorFields() {
    return Object.keys(vm.errors);
  }

  function init() {
    $http
      .get(JsonApi.buildUrl('/Forgotten/' + $stateParams.userHash))
      .then(response => {
        JsonApi.handleResponse(response, loadUser);
      });
  }

  function loadUser(response) {
    angular.copy(response, vm.user);
  }
}
