const dateFormat = {
  filter: {
    sql: 'yyyy-MM-dd',
    short: 'dd|MM|yyyy',
    human: 'EEEE, d MMMM yyyy',
    humanTime: 'HH:mm - EEEE, d MMMM yyyy',
  },
  moment: {
    sql: 'YYYY-MM-DD',
    short: 'DD|MM|YYYY',
    human: 'dddd, Do MMMM YYYY',
    humanTime: 'HH:mm - dddd, Do MMMM YYYY',
  },
};
export default dateFormat;
