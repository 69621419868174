SearchArrivalModal.$inject = ['hotelSlug', 'close', 'PackageSearchService'];

export default function SearchArrivalModal(hotelSlug, close, Search) {
  /** ============================================ *
   * View Model
   * ============================================= */

  const vm = this;

  vm.hotelSlug = hotelSlug;
  vm.checkin = Search.availabilitySearch.checkin;

  /** ============================================ *
   * View Model : variables
   * ============================================= */

  /** ============================================ *
   * View Model : Functions
   * ============================================= */

  vm.hideModal = close;

  /** ============================================ *
   * Private/Protected functions
   * ============================================= */
}
