import moment from 'moment';

AvailabilityCalendarDirective.$inject = [
  '$http',
  'BookingsJsonApi',
  'CalendarService',
  'PackageSearchService',
  'ModalService',
  'ProductRatingService',
];

export default function AvailabilityCalendarDirective(
  $http,
  JsonApi,
  CalendarService,
  PackageSearch,
  ModalService,
  ProductRatingService
) {
  //var numberOfMonths = 2;
  const defaultAmountOfDays = 2;

  return {
    scope: {
      hotelId: '=availabilityCalendar',
      hotelSlug: '=',
      applyOnline: '=',
      numberOfMonths: '=',
      date: '=',
      callback: '=',
      bookingId: '=',
      hideDate: '=',
      productId: '=',
    },
    templateUrl: 'app/view/hotels/availability.html',
    link: link,
  };

  function link(scope) {
    scope.$watch('hotelSlug', getAvailable);

    scope.firstOfCurrentMonth = null;
    scope.months = {};
    scope.day = moment();
    scope.available = {};
    scope.loading = {availability: false};

    /** ============================================ *
     * View Model : Functions
     * ============================================= */

    scope.nextMonth = nextMonth;
    scope.prevMonth = prevMonth;
    scope.isAvailable = isAvailable;
    scope.isBookable = isBookable;
    scope.inThePast = pastMonth;
    scope.getStyle = getStyle;
    scope.getCheckout = getCheckout;
    scope.showCalendarKey = showCalendarKey;
    scope.redeem = redeem;

    init();

    /** ============================================ *
     * Private/Protected functions
     * ============================================= */

    function init() {
      setMonth();
      buildMonths();
    }

    function showCalendarKey() {
      ModalService.showModal({
        templateUrl: 'app/view/bookings/calendar-key-modal.html',
        controller: [
          'close',
          function (close) {
            this.close = close;
          },
        ],
        bodyClass: 'modal-open',
        controllerAs: 'Key',
      });
    }

    function buildMonths() {
      const firstOfCurrentMonth = angular.copy(scope.firstOfCurrentMonth),
        months = {};

      for (let i = 0; i < scope.numberOfMonths; i++) {
        const monthCode = firstOfCurrentMonth.format('YYYYMM');

        months[monthCode] = {
          firstOfMonth: angular.copy(firstOfCurrentMonth),
          days: CalendarService.buildMonth(firstOfCurrentMonth),
        };

        firstOfCurrentMonth.month(firstOfCurrentMonth.month() + 1);
      }

      angular.copy(months, scope.months);

      getAvailable();
    }

    function setMonth() {
      const date = moment(scope.date);

      scope.firstOfCurrentMonth = moment();

      // If date supplied is larger/later that todays date
      // Then use that one as a starting point
      if (
        typeof scope.date === 'object' &&
        date.diff(scope.firstOfCurrentMonth) > 0
      ) {
        scope.firstOfCurrentMonth = date;
      }

      scope.firstOfCurrentMonth.date(1);
    }

    function nextMonth() {
      if (shouldCall()) {
        scope.firstOfCurrentMonth.month(scope.firstOfCurrentMonth.month() + 1);
        buildMonths();
        redeem();
      }
    }

    function prevMonth() {
      if (shouldCall()) {
        scope.firstOfCurrentMonth.month(scope.firstOfCurrentMonth.month() - 1);
        buildMonths();
        redeem();
      }
    }

    function hasRule(day) {
      let hasRule = false;

      try {
        hasRule =
          scope.available[day.monthCode].availability[day.dayCode]
            .roomsAvailable !== 'noRule';
      } catch (c) {
        hasRule = false;
      }

      return hasRule;
    }

    function isAvailable(day) {
      let available = false;

      try {
        available =
          !inThePast(day) &&
          parseInt(
            scope.available[day.monthCode].availability[day.dayCode]
              .roomsAvailable
          ) > 0;
      } catch (e) {
        available = false;
      }

      return available;
    }

    function isBookable(day) {
      let bookable = false,
        availability;

      try {
        availability = scope.available[day.monthCode].availability[day.dayCode];

        bookable =
          isAvailable(day) &&
          availability.changeoverAllowed &&
          availability.bookable;
      } catch (e) {
        bookable = false;
      }

      return bookable;
    }

    function inThePast(day) {
      return (
        moment().format('MMDD') > day.date.format('MMDD') &&
        moment().format('YYYY') >= day.date.format('YYYY')
      );
    }

    function pastMonth() {
      return (
        moment().format('YYYY') >= scope.firstOfCurrentMonth.format('YYYY') &&
        moment().format('MM') >= scope.firstOfCurrentMonth.format('MM')
      );
    }

    function getStyle(monthCode, day) {
      const loading = !shouldCall(),
        style = {
          inThePast: inThePast(day),
          differentMonth: monthCode !== day.monthCode,
          selected:
            !scope.hideDate &&
            day.date.format('YYYY-MM-D') ==
              moment(scope.date).format('YYYY-MM-D'),
        };

      if (!loading) {
        style.noRule = !hasRule(day);
        style.isAvailable = isAvailable(day);
        style.bookable = isBookable(day);
        style.isNotAvailable = !style.noRule && !style.isAvailable;
      }

      return style;
    }

    function getAvailable() {
      let calling = shouldCall(),
        firstOfCurrentMonth = angular.copy(scope.firstOfCurrentMonth),
        checkin = firstOfCurrentMonth.format('YYYY-MM-D'),
        nights = 0;

      for (let i = 0; i < scope.numberOfMonths; i++) {
        nights += parseInt(
          moment(firstOfCurrentMonth).endOf('month').format('D')
        );
        firstOfCurrentMonth.month(firstOfCurrentMonth.month() + 1);
      }

      calling && (scope.loading.availability = true);

      calling &&
        $http
          .get(
            JsonApi.buildUrl('/availability/', {
              locationId: scope.hotelId,
              slug: scope.hotelSlug,
              checkin: checkin,
              nights: nights,
            })
          )
          .then(response => {
            JsonApi.handleResponse(response, data => {
              angular.copy(data, scope.available);
            });
          })
          .finally(() => {
            scope.loading.availability = false;
          });
    }

    function getCheckout(day) {
      let checkout = null,
        nights = getSearchNights() || defaultAmountOfDays;

      nights &&
        (checkout = moment(day.date).add(nights, 'days').format('YYYY-MM-DD'));

      return checkout;
    }

    function getSearchNights() {
      const checkin = moment(PackageSearch.availabilitySearch.checkin),
        checkout = moment(PackageSearch.availabilitySearch.checkout);

      return checkout.diff(checkin, 'days');
    }

    function shouldCall() {
      return !scope.loading.availability && (scope.hotelId || scope.hotelSlug);
    }

    function redeem() {
      !!scope.productId && ProductRatingService.redeem(scope.productId);
    }
  }
}
