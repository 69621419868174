import moment from 'moment';

import dateFormat from '../config/date-format';

BookingValueService.$inject = ['LocationService', 'BoardService'];

export default function BookingValueService(Location, Board) {
  const service = {
    booking: {},
    dates: {
      checkin: null,
      checkout: null,
      nights: null,
    },
    Board: Board.board,
    price: {
      option: 0,
    },
    AccommodationType: [],
    Location: {},
    guests: {
      adult: 0,
      child: 0,
      infant: 0,
    },
    set,
    setGuests,
    getSaveableBooking: saveableBooking,
    getSearch,
  };

  return service;

  function saveableBooking(admin) {
    const booking = {
      id: service.booking.id,
      boardId: service.booking.Board ? service.booking.Board.id : null,
      search: getSearch(),
      option: {
        AccommodationType: [],
      },
      booking_options: service.booking.booking_options,
      features: service.booking.features,
    };

    angular.forEach(service.AccommodationType, room => {
      booking.option.AccommodationType.push({
        id: room.id,
        Configuration: room.Configuration,
      });
    });

    admin &&
      (booking.userId = service.booking.User ? service.booking.User.id : null);

    return booking;
  }

  function set(booking) {
    service.booking = booking ? {...booking} : {features: {}};

    if (Location.locations.hasOwnProperty(service.booking.Location.slug)) {
      fullSet();
    } else {
      Location.load(service.booking.Location.slug).then(fullSet);
    }
  }

  function fullSet() {
    setFeatures();

    setLocation();
    setBoard();

    setAccommodationTypeFromBooking();
    setAccommodationType();
    setOptionPrice();
    setDates();
    setGuests();
  }

  function setFeatures() {
    !service.booking.hasOwnProperty('features') &&
      (service.booking.features = {});
  }

  function setAccommodationTypeFromBooking() {
    angular.copy([], service.AccommodationType);

    service.booking.AccommodationTypeBooking &&
      angular.forEach(
        service.booking.AccommodationTypeBooking,
        AccommodationTypeBooking => {
          angular.forEach(
            AccommodationTypeBooking.configurations,
            configuration => {
              const accommodationId =
                  AccommodationTypeBooking.AccommodationType.accommodation_id,
                accommodationTypeId =
                  AccommodationTypeBooking.AccommodationType.id,
                room = angular.copy(
                  service.Location.Accommodation[accommodationId]
                    .AccommodationType[accommodationTypeId]
                );

              room.Configuration =
                service.Location.Accommodation[
                  accommodationId
                ].AccommodationType[accommodationTypeId].Configuration[
                  configuration.id
                ];

              service.AccommodationType.push(room);
            }
          );
        }
      );
  }

  function setAccommodationType() {
    service.booking.AccommodationType &&
      angular.copy(
        service.booking.AccommodationType,
        service.AccommodationType
      );
  }

  function setOptionPrice() {
    let price = service.booking.features.hasOwnProperty('manual_price')
      ? service.booking.features.manual_price
      : 0;
    //maybe clean this up?
    //add up price per person

    if (price === 0) {
      const quantities = [
        'partner_adult_qty',
        'partner_child_qty',
        'guest_adult_qty',
        'guest_child_qty',
      ];

      price = quantities
        .map(quantity => {
          const price = quantity.replace('qty', 'price'),
            board = quantity.replace('qty', 'board_price');

          const personPrice =
              service.booking[price] * service.booking[quantity],
            boardPrice = service.booking[board] * service.booking[quantity];

          return personPrice + boardPrice;
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue);

      //add up supplements
      angular.forEach(
        service.booking.AccommodationTypeBooking,
        AccommodationTypeBooking => {
          price +=
            AccommodationTypeBooking.supplement * AccommodationTypeBooking.qty;

          price += AccommodationTypeBooking.configurations.reduce(
            (accumulator, Configuration) =>
              accumulator + Configuration.supplement * service.booking.nights,
            0
          );
        }
      );
    }

    service.price.option = price / 100;
  }

  function setLocation() {
    angular.copy(
      Location.locations[service.booking.Location.slug],
      service.Location
    );
  }

  function setBoard() {
    let boardId;

    try {
      boardId = service.booking.Board.id;
    } catch (e) {
      boardId = Object.keys(Board.board)[0];
    }

    service.booking.Board = Board.board[boardId];
  }

  function setDates() {
    !!service.booking.created &&
      (service.dates.created = new Date(service.booking.created));

    service.dates.checkin = new Date(service.booking.arrive_date);
    service.dates.checkout = new Date(service.booking.depart_date);
    service.dates.nights = service.booking.nights;
  }

  function setGuests() {
    service.guests.adult =
      service.booking.partner_adult_qty + service.booking.guest_adult_qty;
    service.guests.child =
      service.booking.partner_child_qty + service.booking.guest_child_qty;
    service.guests.infant = service.booking.infant_qty;

    updateAdditionalGuests();
    updateChildGuests();
  }

  function updateAdditionalGuests() {
    let i,
      adultGuests = angular.isArray(
        service.booking.booking_options.additionalGuests
      )
        ? service.booking.booking_options.additionalGuests.length
        : 0,
      newAdultGuests = service.guests.adult - 1,
      difference = newAdultGuests - adultGuests;

    if (difference < 0) {
      // people have been removed
      service.booking.booking_options.additionalGuests.splice(difference);
    }

    if (difference > 0) {
      // people have been added
      for (i = 0; i < difference; i++) {
        service.booking.booking_options.additionalGuests.push({
          name: null,
          britishCitizen: null,
        });
      }
    }
  }

  function updateChildGuests() {
    !service.booking.booking_options.hasOwnProperty('children') &&
      (service.booking.booking_options.children = []);

    const existingChildren = service.booking.booking_options.children.length,
      expectedChildren = service.guests.child,
      difference = expectedChildren - existingChildren;

    if (difference < 0) {
      // less than expected
      service.booking.booking_options.children.splice(difference);
    } else if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        service.booking.booking_options.children.push({
          name: null,
          age: null,
        });
      }
    }
  }

  function getSearch() {
    return {
      checkin: moment(service.dates.checkin).format(dateFormat.moment.sql),
      checkout: moment(service.dates.checkout).format(dateFormat.moment.sql),
      nights: moment(service.dates.checkout).diff(
        moment(service.dates.checkin),
        'days'
      ),
      partnerAdult: service.booking.partner_adult_qty,
      partnerChild: service.booking.partner_child_qty,
      guestAdult: service.booking.guest_adult_qty,
      guestChild: service.booking.guest_child_qty,
      infant: service.booking.infant_qty,
      locationId: service.Location.id,
    };
  }
}
