export default function LinkOpenedDirective() {
  return {
    require: 'ngModel',
    link: link,
  };

  function link(scope, element, attributes, ngModel) {
    ngModel.$setValidity('linkOpened', false);
  }
}
