import environment from '../../../environment';

const {bookingUrl} = environment.settings;

SearchResultsController.$inject = [
  '$stateParams',
  '$scope',
  'ModalService',
  'PackageSearchService',
  'LocationService',
  'BookingValueService',
  '$state',
  'MessageService',
  'PriceService',
];

export default function SearchResultsController(
  $stateParams,
  $scope,
  ModalService,
  Search,
  Location,
  BookingValue,
  $state,
  messageService,
  PriceService
) {
  /* ============================================
		 * View Model
		 ============================================= */

  const vm = this;
  const errorMessages = {
      occupancyError:
        'Please select the correct configuration for people and beds.',
      selectAllRoomsError: 'Please select a room configuration for all rooms.',
      generalError:
        'Please check your selection, if you continue to have issues please contact partner choice',
    },
    emptyFilters = {
      rooms: '!',
      accommodationId: '!',
      accessible: '!',
    };

  /* ============================================
		 * View Model : variables
		 ============================================= */
  vm.Board = Search.Board;
  vm.LocationPrices = Search.LocationPrices;
  vm.packages = Search.packages;
  vm.roomOptions = Search.roomOptions;
  vm.locations = Location.locations;
  vm.options = Search.options;

  vm.optionsForms = [];
  vm.configuration = {};
  vm.detail = $stateParams;
  vm.filter = angular.copy(emptyFilters);
  vm.allOptions = false;
  vm.showErrors = false;
  vm.url = bookingUrl;

  /* ============================================
		 * View Model : Functions
		 ============================================= */

  vm.showModal = showConfigurationsModal;
  vm.confirmBooking = confirmBooking;
  vm.setRoomsFilter = setRoomsFilter;
  vm.setAccommodationFilter = setAccommodationFilter;
  vm.setAccessibleFilter = setAccessibleFilter;
  vm.accommodationCount = accommodationCount;
  vm.getOptionPrice = getOptionPrice;
  vm.boardPriceLength = boardPriceLength;
  vm.setBoard = setBoard;

  vm.setSelected = setSelected;
  vm.setAllOptions = setAllOptions;
  /* ============================================
		 * Private/Protected functions
		 ============================================= */

  PriceService.set({
    LocationPrices: Search.LocationPrices,
    AccommodationTypePrices: Search.AccommodationTypePrices,
  });

  $scope.$on('partnerchoice:package-search-complete', clearFilters);

  function clearFilters() {
    angular.copy(emptyFilters, vm.filter);
    vm.allOptions = false;

    // do the thing...
    let bestCount = 0;
    angular.forEach(Search.packages, Package => {
      Package.bestOption && bestCount++;
    });

    bestCount == Search.packages.length && (vm.allOptions = true);
  }

  function showConfigurationsModal(roomType) {
    ModalService.showModal({
      templateUrl: 'app/view/hotels/configurations-modal.html',
      controller: 'ConfigurationModalController',
      controllerAs: 'Configurations',
      inputs: {
        Configuration: roomType.Configuration,
        description: roomType.description,
      },
      bodyClass: 'modal-open',
    });
  }

  function setAllOptions() {
    vm.allOptions = !vm.allOptions;
  }

  function setSelected(option, optionFormIndex) {
    angular.forEach(option.AccommodationType, (AccommodationType, index) => {
      !vm.configuration.hasOwnProperty(optionFormIndex) &&
        (vm.configuration[optionFormIndex] = {});

      const keys = Object.keys(AccommodationType.Configuration);

      if (keys.length == 1) {
        vm.configuration[optionFormIndex][index] =
          AccommodationType.Configuration[keys[0]];
      }
    });

    return option;
  }

  /**
   * @param optionFormIndex
   * @param option
   * @returns {*}
   */

  function extraFormValidation(optionFormIndex, option) {
    let configurationsSelected = 0,
      requiredOccupancy = Search.fullOccupancy(),
      requestOccupancy = {
        occupancy: 0,
        adult: 0,
        extraBeds: 0,
        infant: 0,
      },
      numberOfRooms = Object.keys(option.AccommodationType).length;

    angular.forEach(option.AccommodationType, (roomType, index) => {
      if (
        vm.configuration.hasOwnProperty(optionFormIndex) &&
        vm.configuration[optionFormIndex].hasOwnProperty(index)
      ) {
        roomType.Configuration = vm.configuration[optionFormIndex][index];

        requestOccupancy.occupancy += roomType.Configuration.occupancy;
        requestOccupancy.adult += roomType.Configuration.max_adult;
        requestOccupancy.infant += roomType.Configuration.infant;
        requestOccupancy.extraBeds += roomType.Configuration.extra_beds;

        configurationsSelected++;
      }
    });

    vm['OptionForm' + optionFormIndex].OptionFormValid.$setValidity(
      'configurationSelected',
      configurationsSelected == numberOfRooms
    );
    vm['OptionForm' + optionFormIndex].OptionFormValid.$setValidity(
      'extraBedsSelected',
      requiredOccupancy >= requestOccupancy.extraBeds
    );
    vm['OptionForm' + optionFormIndex].OptionFormValid.$setValidity(
      'occupancy',
      requiredOccupancy == requestOccupancy.occupancy
    );

    vm['OptionForm' + optionFormIndex].OptionFormValid.$setValidity(
      'adult',
      Search.availabilitySearch.partnerAdult +
        Search.availabilitySearch.guestAdult <=
        requestOccupancy.adult
    );

    vm['OptionForm' + optionFormIndex].OptionFormValid.$setValidity(
      'infant',
      Search.availabilitySearch.infant == requestOccupancy.infant
    );

    return option;
  }

  function confirmBooking(optionFormIndex, _option_) {
    let option = angular.copy(_option_);

    option = extraFormValidation(optionFormIndex, option);

    if (vm['OptionForm' + optionFormIndex].$valid) {
      showBookingOptions(option);
    } else {
      vm['OptionForm' + optionFormIndex].OptionFormValid.$error.occupancy &&
        messageService.error(errorMessages.occupancyError);
      vm['OptionForm' + optionFormIndex].OptionFormValid.$error
        .configurationSelected &&
        messageService.error(errorMessages.selectAllRoomsError);
      if (
        vm['OptionForm' + optionFormIndex].OptionFormValid.$error
          .extraBedsSelected ||
        vm['OptionForm' + optionFormIndex].OptionFormValid.$error.adult ||
        vm['OptionForm' + optionFormIndex].OptionFormValid.$error.infant
      ) {
        messageService.error(errorMessages.generalError);
      }
    }
  }

  function setRoomsFilter(number) {
    // Show all results
    vm.allOptions = true;

    clearConfigurations();

    vm.filter.rooms = typeof number !== 'undefined' ? number : '!';
  }

  function accommodationCount(locationId) {
    return Object.keys(vm.locations[locationId].Accommodation).length;
  }

  function setAccommodationFilter(accommodation) {
    clearConfigurations();

    vm.filter.accommodationId =
      typeof accommodation !== 'undefined' ? accommodation.id : '!';
  }

  function setAccessibleFilter(accessible) {
    clearConfigurations();

    vm.filter.accessible = typeof accessible !== 'undefined' ? accessible : '!';
  }

  function clearConfigurations() {
    vm.allOptions = true;

    angular.copy({}, vm.configuration);
  }

  function showBookingOptions(option) {
    ModalService.showModal({
      templateUrl: 'app/view/bookings/extra-info-modal.html',
      controller: 'BookingExtraInfoModalController',
      controllerAs: 'Booking',
      inputs: {
        Option: option,
      },
      bodyClass: 'modal-open',
    })
      .then(modal => modal.close)
      .then(bookingId => {
        bookingId && $state.go('Hotel.my-bookings', {bookingId});
      });
  }

  function boardPriceLength() {
    const boardPrice = vm.LocationPrices.boardPrice || {};

    return Object.keys(boardPrice).length;
  }

  function setBoard(board) {
    Search.options.board = board;
  }

  function getOptionPrice(_option_, optionFormIndex) {
    const copyOfOption = angular.copy(_option_);

    angular.forEach(
      copyOfOption.AccommodationType,
      (accommodationType, index) => {
        accommodationType.Configuration = angular.copy(
          vm.configuration[optionFormIndex][index]
        );
      }
    );

    return PriceService.get({
      search: Search.availabilitySearch,
      AccommodationType: copyOfOption.AccommodationType,
      boardSlug: Search.options.board,
    });
  }
}
