import environment from '../../../../environment';
import {receiveEmails} from '../../../../environment/constants';
import modalController from '../../../common/controller/modal-controller';

const {mailchimpSubscribeUrl} = environment.settings;

EditUserController.$inject = ['$window', 'close', 'UserService', 'fields'];

export default function EditUserController(
  $window,
  close,
  UserService,
  fields
) {
  modalController.call(this, close);

  const vm = this;

  vm.title = 'Edit your user details.';

  vm.user = fields.reduce((response, field) => {
    response[field] = userField(field);
    return response;
  }, {});

  vm.features = UserService.user.features;
  vm.receiveEmailsText = receiveEmails.text;
  vm.receiveEmailsLabel = receiveEmails.label;

  vm.emailChange = false;
  vm.footer.disabled = disabled;
  vm.confirm = confirm;
  vm.mailchimpPopup = mailchimpPopup;

  function disabled() {
    let invalid = vm.editForm.$invalid || !vm.editForm.$valid;

    if (vm.user.hasOwnProperty('password')) {
      invalid =
        invalid ||
        !vm.user.password ||
        vm.user.password !== vm.user.passwordConfirm;
    }

    return invalid;
  }

  function mailchimpPopup() {
    const {
      email,
      first_name: firstName,
      last_name: lastName,
    } = UserService.user;

    const url = [
      `${mailchimpSubscribeUrl}`,
      `MERGE0=${email}`,
      `MERGE1=${firstName}`,
      `MERGE2=${lastName}`,
    ].join('&');
    $window.open(url, 'receive marketing emails', 'menubar=0');
    close(true, 200);
  }

  function confirm() {
    const {
      preferences,
      email,
      passwordCurrent,
      password,
      passwordConfirm,
      ..._user
    } = vm.user;

    const isPreferencesChange = !!preferences;
    const isPasswordChange = !!password;
    const isEmailChange = vm.emailChange;

    let user;

    switch (true) {
      case isPreferencesChange:
        user = {preferences};
        break;
      case isEmailChange:
        user = {email, passwordCurrent, ..._user};
        break;
      case isPasswordChange:
        user = {
          passwordCurrent,
          password,
          passwordConfirm,
        };
        break;
      default:
        user = {..._user};
    }

    if (Object.keys(user).length === 0) {
      return;
    }

    UserService.editUser(user)
      .then(() => close(200))
      .catch(errors => (vm.errors = errors));
  }

  function userField(field) {
    const {[field]: _userField} = UserService.user;

    return userField ? angular.copy(_userField) : null;
  }
}
