export default function BookingRoutesProvider() {
  this.$get = function () {
    return {
      Hotel: {
        parent: 'Page',
        abstract: true,
      },
      'Hotel.search': {
        // parent: 'Page',
        url: '/bookings/search/:location/:checkin/:checkout',
        views: {
          'main@Page': {
            templateUrl: 'app/view/hotels/search-form.html',
            controller: 'SearchController',
            controllerAs: 'Search',
          },
          'results@Hotel.search': {
            templateUrl: 'app/view/hotels/search-descriptions.html',
          },
        },
        params: {
          location: {
            value: null,
            squash: true,
          },
          checkin: {
            value: getTheDate(),
            squash: false,
          },
          checkout: {
            value: null,
            squash: true,
          },
        },
      },
      'Hotel.search.result': {
        views: {
          results: {
            templateUrl: 'app/view/hotels/search-results.html',
            controller: 'SearchResultsController',
            controllerAs: 'Results',
          },
        },
        params: {
          location: {
            value: null,
            squash: true,
          },
          checkin: {
            value: null,
            squash: true,
          },
          checkout: {
            value: null,
            squash: true,
          },
        },
      },
      'Hotel.my-bookings': {
        url: '/my-bookings',
        views: {
          'main@Page': {
            templateUrl: 'app/view/bookings/my-bookings.html',
            controller: 'MyBookingsController',
            controllerAs: 'MyBookings',
          },
        },
        params: {
          bookingId: {
            value: null,
            squash: true,
          },
        },
      },
    };
  };

  function getTheDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  }
}
