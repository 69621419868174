PriceService.$inject = [];

export default function PriceService() {
  const service = {
    set,
    get,
    saveablePrices,
    prices: {},
  };

  return service;

  function set(_prices_) {
    angular.copy(_prices_, service.prices);
  }

  function saveablePrices(data) {
    const boardSlug = data.boardSlug;

    const board = service.prices.LocationPrices.boardPrice[boardSlug],
      supplements = {};

    angular.forEach(
      service.prices.AccommodationTypePrices,
      (AccommodationTypePrice, id) => {
        supplements[id] = AccommodationTypePrice.supplement;
      }
    );

    const priceSave = {
      peoplePrices: {
        partnerAdult: service.prices.LocationPrices.peoplePrice.partnerAdult,
        partnerChild: service.prices.LocationPrices.peoplePrice.partnerChild,
        guestAdult: service.prices.LocationPrices.peoplePrice.guestAdult,
        guestChild: service.prices.LocationPrices.peoplePrice.guestChild,
      },
      boardPrices: {
        partnerAdult: 0,
        partnerChild: 0,
        guestAdult: 0,
        guestChild: 0,
      },
      supplements: supplements,
    };

    board &&
      (priceSave.boardPrices = {
        partnerAdult: board.partnerAdult,
        partnerChild: board.partnerChild,
        guestAdult: board.guestAdult,
        guestChild: board.guestChild,
      });

    return priceSave;
  }

  /**
   *
   * @param data
   * @returns {number}
   */
  function get(data) {
    let price = 0,
      search = data.search;

    if (Object.keys(service.prices).length) {
      angular.forEach(
        service.prices.LocationPrices.peoplePrice,
        (amount, label) => {
          price += search[label] * amount;
        }
      );

      data.boardSlug &&
        angular.forEach(
          service.prices.LocationPrices.boardPrice[data.boardSlug],
          (amount, label) => {
            price += search[label] * amount;
          }
        );

      angular.forEach(data.AccommodationType, AccommodationType => {
        price +=
          service.prices.AccommodationTypePrices[AccommodationType.id]
            .supplement;
        AccommodationType.Configuration &&
          (price += AccommodationType.Configuration.supplement * search.nights);
      });
    }

    return price / 100;
  }
}
